import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero";
import {graphql, useStaticQuery} from "gatsby";
import Container from "../styles/Container";

export default () => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/computer.png/" }) {
        childImageSharp {
          fluid(maxWidth: 4800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);
  return (
    <Layout>
      <SEO
        title="Get Started"
       description="How to get started with digital employee handbook software."
      />


      <Hero
        image={data.logo.childImageSharp.fluid}
        title='Get Started'
        text='Getting started with Clean Catalog is easy and fast. Contact us to get going, and read below for an outline of what the setup process looks like.'
      />

      <Container>

      <p className="first-p"> While we can work with your timeline and process, here are the general steps we
        follow.</p>

      <div className="numbered-section">
        <h2 className="numbered-heading"><span>1</span> Let Us Know</h2>
        <p>Fill out our contact form or send us an email at <a
          href="mailto:info@cleancatalog.com">info@cleancatalog.com</a>.
          We'll talk with you about the specifics of your project and any customizations, and then we'll get started.
        </p>
      </div>


      <div className="numbered-section">
        <h2 className="numbered-heading"><span>2</span> Send us your current document, and
          we build the digital version for you</h2>

        <p>No one likes data entry, so we've built importers that can automate much of the work of importing your
          catalog
          from its print format to Clean Catalog. We still review it — some things take a human eye to catch — but
          automation prevents typos and other errors and speeds the process along.
        </p>
      </div>

      <div className="numbered-section">
        <h2 className="numbered-heading"><span>3</span> We revise and adjust as needed</h2>

        <p>Clean Catalog is flexible, and there are multiple ways to build things out. We work with you to get everything exactly how you
          want it, with plenty of communication and revisions along the way.
        </p>
      </div>

      <div className="numbered-section">
        <h2 className="numbered-heading"><span>4</span> We build out users and workflows</h2>

        <p>If you want your catalog up right away, we can launch it with minimal users and workflows, and then build
          those
          out after launch. We'll work with you to figure out the best system for your organization, and we'll build out the appropriate user accounts and workflow.
        </p>
      </div>

      <div className="numbered-section">
        <h2 className="numbered-heading"><span>5</span> We continue to support and stay and touch.</h2>

        <p>We always want to hear from our customers about how we can improve Clean Catalog and better meet their needs,
          so we'll continue to be in touch after launch.
        </p>
      </div>
      </Container>
    </Layout>
  )

}

